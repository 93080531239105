import * as React from "react"
import Layout from "../../components/layout"
import Subscribe from "../../components/subscribe"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const financialsPage  = () => (
    <Layout siteTitle={"WiN's Financials"}>

    {/* top banner */}
    <div className="relative bg-indigo-800">
      <div className="absolute inset-0">
        <StaticImage
          className="h-full w-full object-cover"
          src="../../images/FinancialsBanner.jpg"
          alt=""
        />
        <div className="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Financials</h1>
        <p className="mt-6 font-light max-w-3xl text-xl text-indigo-100">
        In order to foster a deep sense of trust with our donors, partners, and the public, WiN is dedicated to full finanical transparency
        </p>
      </div>
    </div>

    {/* main body */}
    <div className="bg-white">
      <div className="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-xl font-bold tracking-tight text-gray-900">
            Women in Neuroscience is a registered <br />501(c)(3) nonprofit organization<br />(EIN# 85-4295528)
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
          WiN is funded through a combination of sources, including government grants, private foundations, corporate sponsorships, and individual donations. Additionally, WiN may receive funding through grants from universities or research institutions.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://drive.google.com/file/d/1YUpYX3IkhlYA8Il4IMTgsgFEr8TzWoal/view?usp=sharing"
              className="rounded-md bg-indigo-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              2021 Annual Report
            </a>
            <a
              href="https://drive.google.com/uc?export=download&id=1I4e-IFIt4paT5gVn-pLcvXYhvHGoV27_"
              className="rounded-md bg-indigo-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              2022 Annual Report
            </a>
            <a href="https://www.irs.gov/pub/irs-pdf/f990.pdf" className="text-base font-semibold leading-7 hover:underline hover:underline-offset-4 text-gray-900">
            2022 Form 990 <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    {/* email subscribe */}
    <Subscribe />

  </Layout>
)

export const Head = () => <Seo title="WiN's Financials" />

export default financialsPage